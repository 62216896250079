<template>
  <div class="page-slideout">
    <div class="section">
      <div class="container">
        <div class="content-policy">
          <div class="d-flex flex-row align-center justify-space-between">
            <span class="txt-lastupdated">
              {{ $t("generalpage.lateupdate") }}</span
            >
            <div class="buttons py-0">
              <button
                class="btn rounded-5 gl-bt"
                @click="gotosaveandprintFunc()"
              >
                <span>{{ $t("generalpage.btn_printorsave") }}</span>
              </button>
            </div>
          </div>
          <v-divider />

          <div id="printMe">
            <h3 class="mt-0 pb-4 text-center">
              {{ $t("generalpage.smtitle1") }}
            </h3>

            <ol>
              <li class="title">
                {{ $t("generalpage.smtitle1") }}
                <ol>
                  <li>
                    {{ $t("generalpage.smlabel1_1") }}
                  </li>
                  <li>
                    {{ $t("generalpage.smlabel1_2") }}
                  </li>
                  <li>
                    {{ $t("generalpage.smlabel1_3") }}
                  </li>
                  <li>
                    {{ $t("generalpage.smlabel1_4") }}
                  </li>
                </ol>
              </li>

              <!--//-->

              <li class="title">
                {{ $t("generalpage.smtitle2") }}
                <ol>
                  <li>
                    <div class="info-row">
                      <span>{{ $t("generalpage.smlabel2_1_1") }}</span>
                      <span>{{ $t("generalpage.smlabel2_1_2") }}</span>
                      <span>{{ $t("generalpage.smlabel2_1_3") }}</span>
                    </div>
                  </li>

                  <li>
                    <div class="info-row">
                      <span>{{ $t("generalpage.smlabel2_2_1") }}</span>
                      <span>{{ $t("generalpage.smlabel2_2_2") }}</span>
                      <span>{{ $t("generalpage.smlabel2_2_3") }}</span>
                    </div>
                  </li>

                  <li>
                    <div class="info-row">
                      <span>{{ $t("generalpage.smlabel2_3_1") }}</span>
                      <span>{{ $t("generalpage.smlabel2_3_2") }}</span>
                      <span>{{ $t("generalpage.smlabel2_3_3") }} </span>
                    </div>
                  </li>

                  <li>
                    <div class="info-row">
                      <span>{{ $t("generalpage.smlabel2_4_1") }} </span>
                      <span>{{ $t("generalpage.smlabel2_4_2") }}</span>
                      <span>{{ $t("generalpage.smlabel2_4_3") }}</span>
                    </div>
                  </li>

                  <li>
                    <div class="info-row">
                      <span>{{ $t("generalpage.smlabel2_5_1") }}</span>
                      <span>{{ $t("generalpage.smlabel2_5_2") }}</span>
                      <span>{{ $t("generalpage.smlabel2_5_3") }}</span>
                    </div>
                  </li>

                  <li>
                    <div class="info-row">
                      <span>{{ $t("generalpage.smlabel2_6_1") }}</span>
                      <span>{{ $t("generalpage.smlabel2_6_2") }}</span>
                      <span>{{ $t("generalpage.smlabel2_6_3") }}</span>
                    </div>
                  </li>

                  <li>
                    <div class="info-row">
                      <span>{{ $t("generalpage.smlabel2_7_1") }}</span>
                      <span>{{ $t("generalpage.smlabel2_7_2") }}</span>
                      <span>{{ $t("generalpage.smlabel2_7_3") }} </span>
                    </div>
                  </li>

                  <li>
                    <div class="info-row">
                      <span>{{ $t("generalpage.smlabel2_8_1") }}</span>
                      <span>{{ $t("generalpage.smlabel2_8_2") }}</span>
                      <span>{{ $t("generalpage.smlabel2_8_3") }}</span>
                    </div>
                  </li>

                  <li>
                    <div class="info-row">
                      <span>{{ $t("generalpage.smlabel2_9_1") }}</span>
                      <span>{{ $t("generalpage.smlabel2_9_2") }}</span>
                      <span>{{ $t("generalpage.smlabel2_9_3") }}</span>
                    </div>
                  </li>

                  <li>
                    <div class="info-row">
                      <span>{{ $t("generalpage.smlabel2_10_1") }}</span>
                      <span>{{ $t("generalpage.smlabel2_10_2") }}</span>
                      <span>{{ $t("generalpage.smlabel2_10_3") }}</span>
                    </div>
                  </li>

                  <li>
                    <div class="info-row">
                      <span>{{ $t("generalpage.smlabel2_11_1") }}</span>
                      <span>{{ $t("generalpage.smlabel2_11_2") }}</span>
                      <span>{{ $t("generalpage.smlabel2_11_3") }}</span>
                    </div>
                  </li>

                  <li>
                    <div class="info-row">
                      <span>{{ $t("generalpage.smlabel2_12_1") }}</span>
                      <span>{{ $t("generalpage.smlabel2_12_2") }}</span>
                      <span>{{ $t("generalpage.smlabel2_12_3") }}</span>
                    </div>
                  </li>

                  <li>
                    <div class="info-row">
                      <span>{{ $t("generalpage.smlabel2_13_1") }}</span>
                      <span>{{ $t("generalpage.smlabel2_13_2") }}</span>
                      <span>{{ $t("generalpage.smlabel2_13_3") }}</span>
                    </div>
                  </li>

                  <li>
                    <div class="info-row">
                      <span>{{ $t("generalpage.smlabel2_14_1") }}</span>
                      <span>{{ $t("generalpage.smlabel2_14_2") }}</span>
                      <span>{{ $t("generalpage.smlabel2_14_3") }}</span>
                    </div>
                  </li>
                </ol>
              </li>

              <!--//-->

              <li class="title">
                {{ $t("generalpage.smtitle3") }}
                <ol>
                  <li>
                    {{ $t("generalpage.smlabel3_1") }}
                  </li>
                  <li>
                    {{ $t("generalpage.smlabel3_2") }}
                  </li>

                  <li>
                    {{ $t("generalpage.smlabel3_3") }}
                  </li>

                  <li>
                    {{ $t("generalpage.smlabel3_4") }}
                  </li>

                  <li v-if="$t('generalpage.smlabel3_5')">
                    {{ $t("generalpage.smlabel3_5") }}
                  </li>
                </ol>
              </li>

              <!--//-->

              <li class="title">
                {{ $t("generalpage.smtitle4") }}
                <ol>
                  <li>
                    {{ $t("generalpage.smlabel4_1") }}
                  </li>

                  <li>
                    {{ $t("generalpage.smlabel4_2") }}
                  </li>

                  <li>
                    {{ $t("generalpage.smlabel4_3") }}
                  </li>

                  <li>
                    {{ $t("generalpage.smlabel4_4") }}
                  </li>
                </ol>
              </li>

              <!--//-->

              <li class="title">
                {{ $t("generalpage.smtitle5") }}
                <ol>
                  <li>
                    {{ $t("generalpage.smlabel5_1") }}
                  </li>

                  <li>
                    {{ $t("generalpage.smlabel5_2") }}
                  </li>

                  <li>
                    {{ $t("generalpage.smlabel5_3") }}
                  </li>

                  <li>
                    {{ $t("generalpage.smlabel5_4") }}
                  </li>

                  <li v-if="$t('generalpage.smlabel5_5')">
                    {{ $t("generalpage.smlabel5_5") }}
                  </li>

                  <li v-if="$t('generalpage.smlabel5_6')">
                    {{ $t("generalpage.smlabel5_6") }}
                  </li>
                </ol>
              </li>

              <!--//-->

              <li class="title">
                {{ $t("generalpage.smtitle6") }}
                <ol>
                  <li>
                    {{ $t("generalpage.smlabel6_1") }}
                  </li>

                  <li>
                    {{ $t("generalpage.smlabel6_2") }}
                  </li>

                  <li>
                    {{ $t("generalpage.smlabel6_3") }}
                    <ol>
                      <li>
                        {{ $t("generalpage.smlabel6_3_1") }}
                      </li>

                      <li>
                        {{ $t("generalpage.smlabel6_3_2") }}
                      </li>
                    </ol>
                    {{ $t("generalpage.smlabel6_3_end") }}
                  </li>

                  <li>
                    {{ $t("generalpage.smlabel6_4") }}
                  </li>

                  <li>
                    {{ $t("generalpage.smlabel6_5") }}
                  </li>

                  <li>
                    {{ $t("generalpage.smlabel6_6") }}
                  </li>

                  <li>
                    {{ $t("generalpage.smlabel6_7") }}
                  </li>
                </ol>
              </li>

              <!--//-->

              <li class="title">
                {{ $t("generalpage.smtitle7") }}
                <ol>
                  <li>
                    {{ $t("generalpage.smlabel7_1") }}
                  </li>

                  <li>
                    {{ $t("generalpage.smlabel7_2") }}
                  </li>

                  <li>
                    {{ $t("generalpage.smlabel7_3") }}
                  </li>

                  <li>
                    {{ $t("generalpage.smlabel7_4") }}
                  </li>
                </ol>
              </li>

              <!--//-->

              <li class="title">
                {{ $t("generalpage.smtitle8") }}
                <ol>
                  <li>
                    {{ $t("generalpage.smlabel8_1") }}
                  </li>

                  <li>
                    {{ $t("generalpage.smlabel8_2") }}
                  </li>

                  <li>
                    {{ $t("generalpage.smlabel8_3") }}
                  </li>
                </ol>
              </li>

              <!--//-->

              <li class="title">
                {{ $t("generalpage.smtitle9") }}
                <ol>
                  <li>
                    {{ $t("generalpage.smlabel9_1") }}
                  </li>

                  <li>
                    {{ $t("generalpage.smlabel9_2") }}
                  </li>

                  <li>
                    {{ $t("generalpage.smlabel9_3") }}
                  </li>

                  <li>
                    {{ $t("generalpage.smlabel9_4") }}
                  </li>

                  <li>
                    {{ $t("generalpage.smlabel9_5") }}
                  </li>

                  <li>
                    {{ $t("generalpage.smlabel9_6") }}
                  </li>
                </ol>
              </li>

              <!--//-->

              <li class="title">
                {{ $t("generalpage.smtitle10") }}
                <ol>
                  <li>
                    {{ $t("generalpage.smlabel10_1") }}
                  </li>

                  <li>
                    {{ $t("generalpage.smlabel10_2") }}
                  </li>

                  <li>
                    {{ $t("generalpage.smlabel10_3") }}
                  </li>
                </ol>
              </li>

              <!--//-->

              <li class="title">
                {{ $t("generalpage.smtitle11") }}
                <ol>
                  <li>
                    {{ $t("generalpage.smlabel11_1") }}
                  </li>

                  <li>
                    {{ $t("generalpage.smlabel11_2") }}
                  </li>

                  <li>
                    {{ $t("generalpage.smlabel11_3") }}
                  </li>
                </ol>
              </li>

              <!--//-->

              <li class="title">
                {{ $t("generalpage.smtitle12") }}
                <ol>
                  <li>
                    {{ $t("generalpage.smlabel12_1") }}
                  </li>

                  <li>
                    {{ $t("generalpage.smlabel12_2") }}
                  </li>
                </ol>
              </li>

              <li class="title">
                {{ $t("generalpage.smtitle13") }}
                <ol>
                  <li>
                    {{ $t("generalpage.smlabel13_1") }}
                  </li>

                  <li>
                    {{ $t("generalpage.smlabel13_2") }}
                  </li>

                  <li>
                    {{ $t("generalpage.smlabel13_3") }}
                  </li>

                  <li>
                    {{ $t("generalpage.smlabel13_4") }}
                  </li>
                </ol>
              </li>

              <!--//-->
            </ol>

            <div class="d-flex flex-column">
              <v-divider />
              <span class="txt-lastupdated">
                {{ $t("generalpage.lateupdate") }}</span
              >
            </div>
          </div>

          <div class="buttons text-left">
            <button
              class="btn rounded-5 gl-bt"
              type="button"
              @click="gobackFunc()"
            >
              <span>{{ $t("generalpage.btn_close") }}</span>
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import i18n from "@/plugins/i18n";
import { EventBus } from "@/EventBus";

export default {
  name: "GeneralFormScreen",
  metaInfo: {
    title: "Aigent Online Platform Service Agreement",
    titleTemplate: "%s | Aigent",
  },
  data: () => ({
    language: "th",
    pathrightphoto: `${process.env.VUE_APP_PIC_API}/src/aigent_signup_bg.jpg`,
    output: null,
  }),
  created() {
    this.initLanguage();
  },
  mounted() {
    EventBus.$on("refreshRightPhoto", this.initLanguage);
    this.colorHover();
  },
  methods: {
    colorHover() {
      const button = document.getElementById("hover_signin_btn");
      // console.log('hover_signin_btn')
      button.addEventListener("mousemove", (e) => {
        const { x, y } = button.getBoundingClientRect();
        button.style.setProperty("--x", e.clientX - x);
        button.style.setProperty("--y", e.clientY - y);
      });
    },
    initLanguage() {
      const self = this;
      if (localStorage?.getItem("language") == "th" || i18n?.locale == "th")
        self.language = "th";
      else self.language = "en";
    },
    gobackFunc() {
      // window.close();
      window.open("", "_self").close();
    },
    async gotosaveandprintFunc() {
      await this.$htmlToPaper("printMe");
    },
  },
};
</script>

<style>
@import "../../../styles/bootstrap/global.css";

.btn.gl-bt {
  overflow: hidden;
  border: 0;
  background-color: var(--pink);
  padding: 0 0.625rem;
}

.btn.gl-bt:hover {
  background-color: #652b80;
}

.btn.gl-bt::before {
  top: calc(var(--y, 0) * 1px - 50px);
  left: calc(var(--x, 0) * 1px - 50px);
  --size: 0;
  content: "";
  position: absolute;
  width: var(--size);
  height: var(--size);
  background: radial-gradient(circle closest-side, #e8107f, transparent);
  transform: translate(-50%, -50%);
  transition: width 0.2s ease, height 0.2s ease;
}

.gl-bt:hover:before {
  --size: 350px;
}

.v-application .title {
  font-family: "Prompt", sans-serif !important;
}
</style>
